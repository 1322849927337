<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{}">
        <v-dialog
          v-if="type === 'appointment'"
          width="600"
          v-model="select_dialog"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" v-if="showAddButton">
              <v-icon large>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <v-card id="select_expense">
            <v-card-title>
              {{ $t("expenses.whitch_appointment") }}
            </v-card-title>

            <div class="close">
              <v-btn icon @click="select_dialog = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="6" md="6">
                  <base-border-card
                    :title="$t('appointments.price_ap')"
                    @card_clicked="
                      $emit('open_price');
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
                <v-col cols="6" md="6" v-if="$store.getters['auth/isStudio']">
                  <base-border-card
                    :title="$t('expenses.sell_stock')"
                    @card_clicked="
                      $refs.formMove.openForm();
                      $refs.formMove.fetchAll();
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
                <v-col cols="6" md="6">
                  <base-border-card
                    :title="$tc('consent-form.other', 2)"
                    @card_clicked="
                      open();
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-else-if="type === 'confirmed_gastos'"
          width="570"
          v-model="select_dialog"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              :style="absolute ? 'position: absolute;right: 0px;top: 0px;' : ''"
              v-if="showAddButton"
            >
              <v-icon :large="!absolute">mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <v-card id="select_expense">
            <v-card-title
              style="text-decoration: none !important"
              class="text-center d-block text-uppercase"
            >
              {{ $t("expenses.whitch_appointment") }}
            </v-card-title>

            <div class="close">
              <v-btn icon @click="select_dialog = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="8" md="6" v-if="$store.getters['auth/isStudio']">
                  <base-border-card
                    :title="$t('expenses.pay_artist')"
                    @card_clicked="
                      $emit('to_debit');
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
                <v-col cols="8" md="6">
                  <base-border-card
                    :title="$tc('expenses.cash_output', 2)"
                    @card_clicked="
                      $refs.output.dialog = true;
                      $refs.output.expense = {};
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>

                <v-col cols="8" md="6" v-if="$store.getters['auth/isStudio']">
                  <base-border-card
                    :title="$t('expenses.buy_stock')"
                    @card_clicked="
                      $refs.formMove.openForm();
                      $refs.formMove.fetchAll();
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
                <v-col cols="8" md="6">
                  <base-border-card
                    :title="$tc('expenses.other_gasto', 2)"
                    @card_clicked="
                      open();
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-else-if="type === 'confirmed_ingresos'"
          width="570"
          v-model="select_dialog"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              v-if="showAddButton"
              :style="absolute ? 'position: absolute;right: 0px;top: 0px;' : ''"
            >
              <v-icon :large="!absolute">mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <v-card id="select_expense">
            <v-card-title style="text-decoration: none !important" class="">
              {{ $t("expenses.whitch_appointment") }}
            </v-card-title>

            <div class="close">
              <v-btn icon @click="select_dialog = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" md="6">
                  <base-border-card
                    :title="$t('expenses.services')"
                    @card_clicked="$refs.services.open()"
                  >
                  </base-border-card>
                </v-col>
                <v-col cols="12" md="6">
                  <base-border-card
                    :title="$tc('expenses.cash_input', 2)"
                    @card_clicked="
                      $refs.output.dialog = true;
                      $refs.output.expense = {};
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>

                <v-col cols="12" md="6">
                  <base-border-card
                    :title="$t('expenses.sell_stock')"
                    @card_clicked="
                      $refs.formMove.openForm();
                      $refs.formMove.fetchAll();
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
                <v-col cols="12" md="6">
                  <base-border-card
                    :title="$tc('expenses.other_income', 2)"
                    @card_clicked="
                      open();
                      select_dialog = false;
                    "
                  >
                  </base-border-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-btn
          icon
          @click="open"
          v-else-if="showAddButton"
          :style="absolute ? 'position: absolute;right: 0px;top: 0px;' : ''"
        >
          <v-icon :large="!absolute">mdi-plus-circle</v-icon>
        </v-btn>
      </template>

      <v-card id="ExpensesForm">
        <v-card-title
          class="headline"
          v-if="this.action === 'edit' && this.expense.cantidad >= 0"
          >{{ $t("expenses.types.editIngreso") }}</v-card-title
        >
        <v-card-title
          class="headline"
          v-else-if="this.action === 'edit' && this.expense.cantidad < 0"
          >{{ $t("expenses.editExpense") }}
        </v-card-title>
        <v-card-title class="headline" v-else-if="type === 'appointment'">
          {{ $t("expenses.editExpense-Ingreso") }}
        </v-card-title>
        <v-card-title
          class="headline"
          v-else-if="type === 'confirmed_gastos' && this.action === 'save'"
          >{{ $t("expenses.newExpense") }}
        </v-card-title>
        <v-card-title
          class="headline"
          v-else-if="
            this.type === 'confirmed_ingresos' && this.action === 'save'
          "
          >{{ $t("expenses.types.newIngreso") }}
        </v-card-title>
        <v-card-title
          class="headline"
          v-else-if="
            (type === 'cash' || type === 'total') && this.action === 'save'
          "
          >{{ $t("expenses.newExpense-Ingreso") }}
        </v-card-title>
        <v-card-title class="headline" v-else-if="type === 'balance'">{{
          $t("expenses.types.debid")
        }}</v-card-title>
        <v-card-title
          class="headline"
          v-else-if="type === 'balance-liquidation'"
        >
          {{ $t("economys.forecast.liquidate_partial") }}
        </v-card-title>
        <v-card-title class="headline" v-else-if="type === 'services'">
          {{ $tc("expenses.services") }}
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <form
          action
          data-vv-scope="expenses-form"
          @submit.prevent="validateForm('expenses-form')"
        >
          <v-card-title
            class="d-block text-center mt-4"
            v-if="type == 'tattooer' || type == 'customer'"
            style="text-decoration: underline; text-transform: uppercase; font-size: 24px !important;"
          >
            {{ $t("new_payment") }}</v-card-title
          >
          <v-card-text>
            <!--Contenido del formulario-->
            <v-row v-if="type == 'services'">
              <v-col cols="12" sm="6" md="6" class="py-0">
                <label>{{ $t("expenses.data") }}</label>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  class="pointer"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-validate="'required'"
                      data-vv-name="data"
                      :error-messages="errors.collect('expenses-form.data')"
                      :value="
                        expense.data
                          ? $d(new Date(expense.data), 'date2digits')
                          : ''
                      "
                      :disabled="type == 'cash' || type == 'appointment'"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="expense.data"
                    color="primary"
                    no-title
                    scrollable
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="py-0"
                v-if="$store.getters['auth/isStudio']"
              >
                <label v-text="$tc('artist')" />
                <v-select
                  clearable
                  v-model="expense.tattooer_id"
                  outlined
                  dense
                  hide-details
                  class="icon"
                  single-line
                  :items="tattooers"
                  data-vv-validate-on="blur"
                  v-validate="
                    type === 'balance' || type === 'service' ? 'required' : ''
                  "
                  data-vv-name="tattooer_id"
                  :error-messages="errors.collect('expenses-form.tattooer_id')"
                  item-value="tattooer_id"
                  :disabled="type === 'appointment'"
                  @input="show = true"
                  @click:clear="show = false"
                  @change="
                    fetchCustomer();
                    fetchDates();
                    calculateComision();
                  "
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.tattooer.user.first_name }}
                    {{ data.item.tattooer.user.last_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.tattooer.user.first_name }}
                    {{ data.item.tattooer.user.last_name }}
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="py-0"
                v-if="$store.getters['auth/isStudio'] && type === 'services'"
              >
                <label v-text="$tc('customer')" />
                <v-autocomplete
                  clearable
                  v-model="expense.customer_id"
                  outlined
                  dense
                  hide-details
                  class="icon"
                  item-value="id"
                  :search-input.sync="searchCustomer"
                  :loading="isLoadingCustomer"
                  cache-items
                  item-text="user.fullname"
                  single-line
                  :items="customers"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="customer_id"
                  :error-messages="errors.collect('expenses-form.customer_id')"
                  @change="fetchDates()"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.user.first_name }}
                    {{ data.item.user.last_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.user.first_name }}
                    {{ data.item.user.last_name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0"
                v-if="$store.getters['auth/isStudio']"
              >
                <label v-text="$tc('appointment')" />
                <v-autocomplete
                  clearable
                  v-model="expense.appointment_id"
                  outlined
                  dense
                  hide-details
                  class="icon"
                  single-line
                  :items="dates"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="appointment_id"
                  :error-messages="
                    errors.collect('expenses-form.appointment_id')
                  "
                  item-value="appointment_id"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.date }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.date }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="
                type !== 'services' ||
                  (type == 'services' &&
                    expense.tattooer_id !== null &&
                    expense.date !== null &&
                    expense.customer_id !== null &&
                    expense.appointment_id !== null)
              "
            >
              <v-radio-group
                row
                v-model="expense.gasto"
                class="pl-2"
                v-if="
                  type == 'appointment' ||
                    type == 'cash' ||
                    type === 'total' ||
                    type === 'balance'
                "
                data-vv-name="gasto"
                :error-messages="errors.collect('expenses-form.gasto')"
                data-vv-validate-on="blur"
                v-validate="'required'"
              >
                <v-radio color=" primary">
                  <template v-slot:label
                    ><div style="margin-top: 6px">
                      {{
                        type !== "balance"
                          ? $t("ingresos")
                          : $t("expenses.ows_me")
                      }}
                    </div>
                  </template>
                </v-radio>
                <v-radio color="primary">
                  <template v-slot:label
                    ><div style="margin-top: 6px">
                      {{
                        type !== "balance" ? $t("gastos") : $t("expenses.i_ow")
                      }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>

              <v-col
                cols="12"
                md="12"
                class="py-0"
                v-if="type !== 'balance-liquidation'"
              >
                <label>{{ $tc("expenses.concepto", 1) }}</label>
                <v-text-field
                  v-model="expense.conceptos"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:2|max:50'"
                  data-vv-name="concepto"
                  :error-messages="errors.collect('expenses-form.concepto')"
                  outlined
                  dense
                  hide-details
                  name="input-7-4"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="py-0">
                <label>{{ $t("expenses.cantidad") }}</label>

                <number-input-with-separators
                  :value="expense"
                  valueKey="cantidad"
                  ref="money"
                  rules="required|is_not:0€"
                  outlined
                  dense
                  class="input_cantidad"
                  type="number"
                  v-on:keypress="isNumber($event)"
                  @change="calculateComision()"
                  :placeholder="max ? `${max}€` : ''"
                  :max="max"
                  ><template> </template>
                </number-input-with-separators>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <label v-text="$t('expenses.pay_mode')" />
                <v-select
                  clearable
                  v-model="expense.pay_mode"
                  outlined
                  dense
                  hide-details
                  v-validate="'required'"
                  data-vv-name="pay"
                  class="icon"
                  single-line
                  :items="pay"
                  :error-messages="errors.collect('expenses-form.pay')"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
                v-if="this.type != 'balance-liquidation' && type != 'services'"
              >
                <label>{{ $t("expenses.data") }}</label>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  class="pointer"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-validate="'required'"
                      data-vv-name="data"
                      :error-messages="errors.collect('expenses-form.data')"
                      :value="
                        expense.data
                          ? $d(new Date(expense.data), 'date2digits')
                          : ''
                      "
                      :disabled="type == 'cash' || type == 'appointment'"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="expense.data"
                    color="primary"
                    no-title
                    scrollable
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                v-if="
                  this.action === 'save' &&
                    this.type != 'appointment' &&
                    this.type != 'balance' &&
                    this.type != 'balance-liquidation' &&
                    this.type != 'services'
                "
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <label>{{ $tc("expenses.recurrente_ask") }}</label>

                <v-select
                  outlined
                  dense
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="recurrente"
                  :error-messages="errors.collect('expenses-form.recurrente')"
                  :items="repeats"
                  v-model="expense.recurrente"
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
                v-if="
                  this.action === 'save' &&
                    this.type != 'appointment' &&
                    this.type != 'balance' &&
                    this.type != 'balance-liquidation' &&
                    this.type != 'services'
                "
                ><label>{{ $t("expenses.until") }}</label>
                <v-menu
                  ref="menu"
                  v-model="menuUntil"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="data"
                  :error-messages="errors.collect('expenses-form.data')"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  :disabled="
                    expense.recurrente == 'none' || expense.recurrente == null
                  "
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      :value="
                        expense.until
                          ? $d(new Date(expense.until), 'date2digits')
                          : ''
                      "
                      readonly
                      :disabled="
                        expense.recurrente == 'none' ||
                          expense.recurrente == null
                      "
                      hide-details
                      class="pointer"
                      v-bind="attrs"
                      v-bind:class="[
                        { 'error--text': expense.until < expense.data },
                      ]"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="expense.until"
                    :min="expense.data"
                    color="primary"
                    no-title
                    scrollable
                    @input="menuUntil = false"
                  ></v-date-picker> </v-menu
              ></v-col>

              <v-col
                cols="12"
                md="6"
                class="py-0"
                v-if="
                  $store.getters['auth/isStudio'] &&
                    type !== 'appointment' &&
                    this.type != 'balance-liquidation' &&
                    type !== 'services'
                "
              >
                <label v-text="$tc('artist')" />
                <v-select
                  clearable
                  v-model="expense.tattooer_id"
                  outlined
                  dense
                  hide-details
                  class="icon"
                  single-line
                  :items="tattooers"
                  data-vv-validate-on="blur"
                  v-validate="
                    type === 'balance' || type === 'service' ? 'required' : ''
                  "
                  data-vv-name="tattooer_id"
                  :error-messages="errors.collect('expenses-form.tattooer_id')"
                  item-value="tattooer_id"
                  :disabled="type === 'appointment'"
                  @input="show = true"
                  @click:clear="show = false"
                  @change="
                    fetchCustomer();
                    fetchDates();
                    calculateComision();
                  "
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.tattooer.user.first_name }}
                    {{ data.item.tattooer.user.last_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.tattooer.user.first_name }}
                    {{ data.item.tattooer.user.last_name }}
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                class="py-0"
                v-if="type === 'balance' || this.type === 'balance-liquidation'"
              >
                <label>{{ $tc("expenses.balance") }}</label>

                <v-autocomplete
                  outlined
                  dense
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="balance"
                  :error-messages="errors.collect('expenses-form.balance')"
                  :items="yesNo"
                  v-model="expense.balance"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col
                :md="type == 'services' ? 12 : 6"
                cols="12"
                class="py-0"
                v-if="
                  type !== 'confirmed_gastos' &&
                    $store.getters['auth/getSetting']('comisions_active') ===
                      1 &&
                    this.type != 'balance' &&
                    expense.gasto !== 1 &&
                    expense.gasto !== true &&
                    this.type != 'balance-liquidation'
                "
              >
                <label v-text="$tc('expenses.commissions')" />
                <v-select
                  clearable
                  v-model="expense.commissions"
                  outlined
                  dense
                  hide-details
                  :items="commissions"
                  @change="calculateComision"
                  :disabled="
                    expense.gasto !== 0 && expense.gasto !== false && show
                  "
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="py-0"
                v-if="
                  type !== 'confirmed_gastos' &&
                    $store.getters['auth/getSetting']('comisions_active') ===
                      1 &&
                    action === 'save' &&
                    this.type != 'balance' &&
                    expense.gasto !== 1 &&
                    expense.gasto !== true &&
                    this.type != 'balance-liquidation'
                "
              >
                <label> {{ $tc("studio_com") }} {{ studioCom }} </label>

                <v-text-field
                  outlined
                  dense
                  hide-details
                  disabled
                  :value="$n(diff, 'currency')"
                ></v-text-field>
              </v-col>
              <v-col
                sm="6"
                cols="12"
                class="py-0"
                v-if="
                  type !== 'confirmed_gastos' &&
                    $store.getters['auth/getSetting']('comisions_active') ===
                      1 &&
                    action === 'save' &&
                    this.type != 'balance' &&
                    expense.gasto !== 1 &&
                    expense.gasto !== true &&
                    this.type != 'balance-liquidation'
                "
              >
                <label> {{ $tc("artist_com") }} {{ tattooerCom }} </label>

                <number-input-with-separators
                  :value="expense"
                  valueKey="comision"
                  ref="money2"
                  outlined
                  dense
                  rules="required|is_not:0€"
                  class="input_cantidad"
                  type="number"
                  v-on:keypress="isNumber($event)"
                  hide-details
                  :disabled="
                    expense.gasto !== 0 && expense.gasto !== false && show
                  "
                  ><template> </template>
                </number-input-with-separators>
              </v-col>

              <v-col cols="12" class="py-0">
                <label>{{ $t("expenses.comment") }}</label>
                <v-text-field
                  v-model="expense.comentario"
                  outlined
                  dense
                  hide-details
                  rows="1"
                  name="input-7-4"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="cancel()"
              style="height: 25px; width: 100px"
              elevation="0"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              type="submit"
              style="
                width: 100px !important;
                color: #363533;
                height: 25px;
                padding-top: 10px !important;
              "
              elevation="0"
              ><v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: $t("") }) }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <FormMove
      :title="type === 'confirmed_gastos' ? 'entradas' : 'salidas'"
      ref="formMove"
      :hidden="true"
      :appointment_id="appointmentId"
      :card="true"
      @update="$emit('update')"
    />
    <Form
      v-if="type === 'confirmed_ingresos'"
      ref="services"
      action="save"
      :expense="expense"
      @update="$emit('update')"
      type="services"
    />
    <CashInput
      v-if="type === 'confirmed_ingresos'"
      ref="output"
      @update="$emit('update')"
      :hidden="true"
    ></CashInput>

    <CashOutput
      v-else
      ref="output"
      @update="$emit('update')"
      :hidden="true"
    ></CashOutput>
  </div>
</template>
<script>
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "ExpensesForm",
  props: [
    "expense",
    "action",
    "type",
    "showAddButton",
    "appointmentId",
    "tattooerId",
    "max",
    "absolute",
  ],
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
    FormMove: () => import("@/components/inventorymovement/FormMove"),
    CashOutput: () => import("@/components/expense/CashOutput"),
    CashInput: () => import("@/components/expense/CashInput"),
    Form: () => import("@/components/expense/Form"),
  },

  watch: {
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomer();
    },
  },
  data() {
    return {
      isLoadingCustomer: false,
      searchCustomer: "",
      select_dialog: false,
      repeats: [
        { text: this.$t("repeats.none"), value: "none" },
        { text: this.$t("repeats.day"), value: "day" },
        { text: this.$t("repeats.week"), value: "week" },
        { text: this.$t("repeats.month"), value: "month" },
        { text: this.$t("repeats.year"), value: "year" },
      ],
      customers: [],
      dates: [],
      studioCom: "",
      tattooerCom: "",
      dialog: false,
      menu: false,
      menuUntil: false,
      show: false,
      tattooers: [],
      item: {},
      yesNo: [
        { text: this.$t("expenses.balances.yes"), value: true },
        { text: this.$t("expenses.balances.no"), value: false },
      ],
      commissions: [
        {
          text: this.$t("expenses.commissions_studio"),
          value: "studio",
        },
        {
          text: this.$t("expenses.commissions_tattooer"),
          value: "tattooer",
        },
      ],
    };
  },
  mounted() {
    this.fetchActiveTattooer();
    //if (this.type === "services") this.open();
  },
  computed: {
    diff() {
      //console.log("diff");
      if (this.expense.comision) {
        return this.expense.cantidad - this.expense.comision;
      }
      return +this.expense.cantidad || 0;
    },
    pay() {
      //console.log("pay");

      let p = [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ];
      if (
        this.$store.getters["auth/getSetting"]("pm_stripe_id") !== 0 &&
        (this.expense.gasto === 0 || this.expense.gasto === false)
      ) {
        p.push({
          text: this.$t("expenses.online"),
          value: "online",
        });
      }
      return p;
    },
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    calculateComision() {
      if (
        this.expense.tattooer_id &&
        this.expense.commissions &&
        this.expense.cantidad
      ) {
        console.log("CALCULAMOS");
        let c = this.tattooers.find(
          (x) => x.tattooer_id === this.expense.tattooer_id
        ).comisions;
        console.log(+c.price);
        console.log(this.expense.cantidad);
        if (c) {
          let comision = c.comisions.find((x) => {
            let from = x.from === "any" || x.from === this.expense.commissions; //true
            let cantidad =
              x.price_pos === "any" ||
              (x.price_pos === "lower" && +this.expense.cantidad <= +c.price) ||
              (x.price_pos === "bigger" && +this.expense.cantidad > +c.price);
            return from && cantidad;
          });
          console.log(comision);
          if (comision.type === "money") {
            this.expense.comision = comision.valor;
          } else {
            this.expense.comision =
              (this.expense.cantidad * +comision.valor) / 100;
          }

          if (comision) {
            this.tattooerCom = `${comision.valor} ${
              comision.type === "money" ? "€" : "%"
            }`;
            let a;
            if (comision.type === "money") {
              a = this.expense.cantidad - comision.valor;
            } else {
              a = 100 - comision.valor;
            }
            this.studioCom = `${a} ${comision.type === "money" ? "€" : "%"}`;
          }
        }
      } else {
        this.studioCom = "";
        this.tattooerCom = "";
        this.expense.comision = null;
      }
      if (this.$refs.money2) this.$refs.money2.change();
    },
    recharge() {
      if (this.$refs.money) this.$refs.money.change();
      if (this.$refs.money2) this.$refs.money2.change();
    },
    ...mapActions("expenses", ["addExpenses", "editExpense"]),
    async validateForm(scope) {
      this.errors.clear();
      let form_val = await this.$validator.validateAll(scope);
      let money_val = await this.$refs.money.validate();
      this.type === "appointment" ? (this.expense.recurrente = "none") : "";
      if (this.type === "balance") {
        this.expense.recurrente = "none";
      }
      console.log(form_val, money_val);
      if (
        (form_val &&
          money_val &&
          this.expense.until > this.expense.data &&
          this.expense.recurrente != "none") ||
        this.expense.recurrente == "none" ||
        this.expense.recurrente == 0
      ) {
        console.log("validando");
        if (this.action === "save") {
          this.addExpenses(this.expense).then((data) => {
            if (data.success) {
              this.dialog = false;
              this.$emit("update");
              this.$alert(this.$t("transactionADD_success"), "", "");
              if (this.expense.pay_mode === "online") {
                this.item = data.expense;
                this.$refs.payment.dialog = true;
              }
            }
          });
        } else {
          this.editExpense(this.expense).then((data) => {
            if (data.success) {
              this.dialog = false;
              this.$emit("update");
              this.$alert(this.$t("transactionEdit_success"), "", "");
            } else {
              this.action = "edit";
            }
          });
          this.dialog = false;

          //////console.log("validated");
        }
      }
      return false;
    },
    open() {
      console.log("OPEN");
      this.errors.clear();

      this.expense.conceptos = null;
      this.expense.cantidad = null;
      this.action = "save";
      this.expense.comentario = null;
      this.expense.tattooer_id = null;
      this.expense.commissions = null;
      this.expense.comision = null;
      this.expense.recurrente = null;
      this.expense.pay_mode = null;
      this.expense.until = null;
      this.expense.gasto = null;
      this.expense.client_id = null;
      this.expense.appointment_id = null;
      this.show = true;

      this.studioCom = "";
      this.tattooerCom = "";
      if (this.type != "cash") {
        this.expense.data = null;
      }

      /* //Ajuste de recurrencia
      switch (this.type) {
        case "confirmed":
        case "no-confirmed":
          this.expense.recurrente = false;
          break;
        case "recurrente":
          this.expense.recurrente = true;
          break;
      }*/

      switch (this.type) {
        case "appointment":
          if (this.action === "save") {
            this.expense.appointment_id = this.appointmentId;
            this.expense.tattooer_id = this.tattooerId;
          }
          this.expense.recurrente = false;
          this.expense.data = moment().format("YYYY-MM-DD");
          /*if (this.expense.gasto >= 0 || !this.expense.gasto) {
            this.expense.gasto = false;
          } else {
            this.expense.gasto = true;
          }*/
          break;
        case "confirmed_gastos":
          this.expense.gasto = true;
          break;
        case "services":
          console.log("customers");
          this.fetchCustomer();
          this.fetchDates();
          this.expense.recurrente = false;
        case "confirmed_ingresos":
          this.expense.gasto = false;
          this.show = false;

          break;
        case "balance-liquidation":
          if (this.max > 0) this.expense.gasto = true;
          else this.expense.gasto = false;
          this.expense.conceptos = this.$t(
            "economys.forecast.liquidate_partial"
          );

          this.expense.data = moment().format("YYYY-MM-DD");
          this.expense.recurrente = false;
          this.expense.tattooer_id = this.tattooerId;
          this.expense.partial = true;
          break;
      }
      this.dialog = true;
      if (this.$refs.money) this.$refs.money.change();
      if (this.$refs.money2) this.$refs.money2.change();
    },
    cancel() {
      this.dialog = false;
      this.$emit("actionsave");
    },
    ...mapActions("studios", ["getActiveTattooer"]),
    fetchActiveTattooer() {
      this.getActiveTattooer().then(
        (data) => (this.tattooers = data) //.map((x) => x.tattooer))
      );
    },
    ...mapActions("customers", ["getCustomers"]),
    fetchCustomer() {
      console.log("customers");
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      this.getCustomers({
        filters: {
          studio_id: this.$store.state.auth.user.studio.id,
          tattooer_id: this.expense.tattooer_id,
          ...filters,
        },
        pagination: {},
      }).then((result) => {
        this.customers = result.data;
        this.isLoadingCustomer = false;
      });
    },
    ...mapActions("appointments", ["getAppointmentsDates"]),

    fetchDates() {
      this.getAppointmentsDates({
        filters: {
          studio_id: this.$store.state.auth.user.studio.id,
          tattooer_id: this.expense.tattooer_id,
          customer_id: this.expense.customer_id,
        },
        pagination: { itemsPerPage: -1 },
      }).then((result) => {
        this.dates = result;
      });
    },
  },
};
</script>

<style lang="sass">
.v-input--selection-controls.v-input .v-label
  line-height: 13px
.v-input--selection-controls .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before
  background: var(--v-primary-base) !important
.v-input--selection-controls
  margin: 0px
  padding: 0px
.theme--dark.v-input--switch .v-input--switch__thumb
  color: var(--v-primary-base) !important

#ExpensesForm
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base)

  label
    display: block
    height: 25px
    color: var(--v-primary-base) !important
  .v-application--is-ltr .v-text-field__suffix
    padding-top: 4px

  .icon
    .theme--dark.v-icon
      color: var(--v-primary-base) !important
</style>
